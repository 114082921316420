import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';

import svFlag from './flags/se.svg';
import gbFlag from './flags/gb.svg';
import noFlag from './flags/no.svg';

import { breakpoint } from '../../theme';

const Switcher = styled.div`
  display: flex;
  display: inline-flex;
  align-items: center;

  ${breakpoint('large')} {
    /* padding: ${(props) => props.theme.spacing.gutter} 0; */
  }
`;

const LanguageItem = styled.button`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.text.primary};
  padding: 0.5rem;
  appearance: none;
  outline: none;
  border: 0;
  line-height: 1;
  font-size: ${rem(18)};
  cursor: pointer;
  opacity: 0.4;
  background: none;

  &:hover {
    opacity: 1;
  }

  ${({ negative, theme }) =>
    negative && `color: ${theme.palette.text.negative};`}

  ${({ active }) => active && `opacity: 1;`}
`;

const LanguageIcon = styled.div`
  display: block;
  border-radius: 999px;
  overflow: hidden;
  width: ${rem(22)};
  height: ${rem(22)};
  margin-right: ${rem(9)};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
`;

function LanguageSwitcher({ negative }) {
  const { t, i18n } = useTranslation();

  const languages = [
    {
      code: 'en',
      label: 'EN',
      name: t('english'),
      image: gbFlag,
    },
    {
      code: 'sv',
      label: 'SV',
      name: t('swedish'),
      image: svFlag,
    },
    {
      code: 'no',
      label: 'NO',
      name: t('norwegian'),
      image: noFlag,
    },
  ];

  function handleChangeLanguage(lang) {
    i18n.changeLanguage(lang);
  }

  return (
    <Switcher>
      {languages.map((lang) => (
        <LanguageItem
          type="button"
          key={lang.code}
          onClick={() => handleChangeLanguage(lang.code)}
          active={lang.code === i18n.language}
          negative={negative}
        >
          <LanguageIcon
            style={{ backgroundImage: `url(${lang.image})` }}
            title={lang.name}
          />
          {lang.label}
        </LanguageItem>
      ))}
    </Switcher>
  );
}

LanguageSwitcher.propTypes = {
  negative: PropTypes.bool,
};

LanguageSwitcher.defaultProps = {
  negative: false,
};

export default LanguageSwitcher;
