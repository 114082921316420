/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// import Menu from '../Menu';
import Link from '../Link';
// import SocialLinks from '../SocialLinks';
import Row from '../Row';
import Col from '../Col';
import LanguageSwitcher from '../LanguageSwitcher';
import { MULTILINGUAL } from '../../constants';

import { breakpoint } from '../../theme';

import nordsignLogo from '../../images/nordsign-logo.svg';
import prepgroupLogo from '../../images/prepgroup-logo-neg.png';

const Root = styled.div`
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  padding: ${rem(60)} ${(props) => props.theme.spacing.columnGutter} ${rem(60)};
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: auto;

  ${breakpoint('large')} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const MainFooter = styled.footer`
  /* border-top: 1px solid ${(props) => props.theme.global.border.color}; */
  background-color: ${(props) => props.theme.palette.background.content};
  text-align: center;

  ${breakpoint('large')} {
    text-align: left;
  }
`;

const LogoCol = styled(Col)`
  margin: 0;
  width: 100%;
  max-width: ${rem(500)};
  flex-shrink: 0;
  margin-bottom: 1rem;
  font-size: ${rem(15)};

  ${breakpoint('large')} {
    margin-bottom: 0;
    max-width: ${rem(300)};
  }

  img {
    width: ${rem(146)};
    /* margin: 0 auto 1.25rem;

    ${breakpoint('large')} {
      margin: 0 0 1.25rem;
    } */
  }
`;

// const NavigationCol = styled(Col)`
//   margin: 0;
//   flex-grow: 1;
//   text-align: center;
//   margin-bottom: 1rem;
//   display: none;

//   ${breakpoint('large')} {
//     display: block;
//     flex-wrap: wrap;
//     margin-bottom: 0;
//     text-align: left;
//   }
// `;

const LanguageCol = styled.div`
  margin: 2.5rem 0 1rem -0.5rem;
  flex-grow: 0;
  width: 100%;
  /* max-width: ${rem(220)}; */
`;

const FooterText = styled(Col)`
  margin: 0;
  flex-grow: 0;
  width: 100%;
  max-width: ${rem(220)};
`;

const BottomLine = styled.footer`
  display: flex;
  padding-top: ${rem(20)};
  padding-bottom: ${rem(20)};
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin: auto;
  color: ${(props) => props.theme.palette.colors.white};
  font-size: ${rem(16)};
  text-align: center;
  text-transform: uppercase;

  ${breakpoint('large')} {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
  }
`;

const BottomLineContainer = styled(Row)`
  width: 100%;
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: auto;
`;

const Copyright = styled(Col)`
  width: 100%;

  ${breakpoint('large')} {
    width: 50%;
    text-align: left;
  }
`;

const Terms = styled(Col)`
  width: 100%;

  ${breakpoint('large')} {
    width: 50%;
    text-align: right;
  }

  a {
    color: ${(props) => props.theme.components.menu.item.colorNegative};

    &:hover {
      color: ${(props) => props.theme.components.menu.item.hoverNegative};
    }
  }
`;

const FooterTitle = styled.h5`
  font-size: ${rem(20)};
  margin-bottom: ${rem(20)};
`;

const FooterNote = styled.p`
  font-size: ${rem(12)};
  color: ${(props) => props.theme.components.menu.item.colorNegative};
`;

// const FooterMenu = styled.div`
//   margin-left: -1.15rem;
//   margin-right: -1.15rem;
// `;

// const PreFooter = styled(Row)`
//   border-top: 1px solid ${(props) => props.theme.global.border.color};
//   padding-top: ${rem(30)};
//   padding-bottom: ${rem(30)};
// `;

// const PreFooterCol = styled(Col)`
//   text-align: center;
//   margin-bottom: 3.5rem;

//   &:last-child {
//     margin-bottom: 0;
//   }

//   ${breakpoint('large')} {
//     margin-bottom: 0;
//   }

//   /* width: 100%;

//   ${breakpoint('large')} {
//     width: 50%;
//   } */
// `;

function Footer({
  logo,
  // navigation
}) {
  const { t } = useTranslation();
  const policyLink = '';
  const termsLink = '/kopvillkor/';
  return (
    <Root>
      <MainFooter>
        <Container>
          <LogoCol>
            {logo}
            <h6 style={{ margin: '1.1rem 0 1.5rem' }}>{t('developedBy')}</h6>
            <Link to="https://nordsign.se/">
              <img src={nordsignLogo} alt="Nordsign AB" loading="lazy" />
              <FooterNote>{t('nordsignDesc')}</FooterNote>
            </Link>
            <Link to="https://simongoot.com/">
              <img src={prepgroupLogo} alt="Prep Group AB" loading="lazy" />
              <FooterNote>{t('prepgroupDesc')}</FooterNote>
            </Link>
            {MULTILINGUAL && (
              <LanguageCol>
                <LanguageSwitcher />
              </LanguageCol>
            )}
          </LogoCol>
          <FooterText>
            <FooterTitle>{t('contact')}</FooterTitle>
            <p>
              E-post:{' '}
              <Link to="mailto:publikfigur.se@gmail.com">
                publikfigur.se@gmail.com
              </Link>
            </p>
          </FooterText>
          {/* <NavigationCol>
            <FooterTitle>{t('links')}</FooterTitle>
            <FooterMenu>
              <Menu
                items={navigation}
                align="start"
                color="negative"
                direction="vertical"
              />
            </FooterMenu>
          </NavigationCol> */}
        </Container>
      </MainFooter>
      <BottomLine>
        <BottomLineContainer>
          <Copyright>&copy; {t('copyrightLine')}</Copyright>{' '}
          <Terms>
            {termsLink && <Link to={termsLink}>{t('termsOfPurchase')}</Link>}

            {policyLink && (
              <>
                {' · '}
                <Link to={policyLink}>{t('privacyPolicy')}</Link>
              </>
            )}
          </Terms>
        </BottomLineContainer>
      </BottomLine>
    </Root>
  );
}

Footer.propTypes = {
  logo: PropTypes.node.isRequired,
  // navigation: PropTypes.arrayOf(PropTypes.object),
};

// Footer.defaultProps = {
//   navigation: [],
// };

export default Footer;
