import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
import Sticky from 'react-sticky-el';
// import { useTranslation } from 'react-i18next';

import CookieConsent from '../CookieConsent';
import Header from '../Header';
import Footer from '../Footer';
import SiteLogo from '../SiteLogo';
import Drawer from '../Drawer';
import AccordionMenu from '../AccordionMenu';
import LanguageSwitcher from '../LanguageSwitcher';
import { MULTILINGUAL } from '../../constants';

const Root = styled.div`
  position: relative;
  /* background-color: ${(props) => props.theme.palette.background.content}; */
`;

const HeaderWrap = styled.div`
  /* position: absolute; // Use Absolute if overlapping should occur per default */
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
`;

const StickyWrap = styled(Sticky)`
  transition: box-shadow 0.5s, background-color 0.25s ease-in-out;
  background-color: ${(props) => props.theme.palette.background.content};

  &.stuck {
    background-color: ${(props) => props.theme.palette.background.content};
    /* box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5); */
  }
`;

const Content = styled.div`
  margin: 0 auto;
  /* background-color: ${(props) => props.theme.palette.background.content}; */
  border-bottom: 1px solid ${(props) => props.theme.palette.background.content}; /* Fix to preven bottom-margin to mess with spacing */

  /* max-width: ${(props) => props.theme.grid.maxWidth};
  padding: 0 1rem 1.5rem; */
`;

const LanguageCol = styled.div`
  margin-top: ${rem(20)};
  padding: ${(props) => props.theme.spacing.columnGutter};
  flex-grow: 0;
  flex-shrink: 1;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.global.border.color};
`;

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        navigations {
          main {
            id
            name
            link
          }
          footer {
            id
            name
            link
          }
        }
      }
    }
    allSportsJson {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
  }
`;

function Layout({ children, headerProps, withHeader, withFooter }) {
  const [drawerOpen, setIsDrawerOpen] = useState(false);
  // const { i18n } = useTranslation();

  const toggleDrawer = () => {
    setIsDrawerOpen(!drawerOpen);
  };

  return (
    <StaticQuery
      query={`${query}`} // String hack needed to fix https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    >
      {({ site }) => {
        const {
          siteMetadata: { navigations },
        } = site;
        // const allMenus = [].concat(...Object.values(navigations));

        const stickyHeader = true;

        return (
          <Root>
            <Drawer open={drawerOpen} toggle={toggleDrawer}>
              <AccordionMenu items={navigations?.main} direction="vertical" />
              {MULTILINGUAL && (
                <LanguageCol>
                  <LanguageSwitcher negative />
                </LanguageCol>
              )}
            </Drawer>

            {withHeader && (
              <HeaderWrap>
                <StickyWrap as={!stickyHeader && 'div'} stickyClassName="stuck">
                  <Header
                    siteTitle={site.siteMetadata.title}
                    logo={<SiteLogo />}
                    drawerToggle={toggleDrawer}
                    navigationStart={navigations?.main}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...headerProps}
                  />
                </StickyWrap>
              </HeaderWrap>
            )}

            <Content>
              <main>{children}</main>
            </Content>

            {withFooter && (
              <Footer
                siteTitle={site.siteMetadata.title}
                logo={<SiteLogo style={{ display: 'inline-block' }} />}
                navigation={navigations?.footer}
              />
            )}

            <CookieConsent />
          </Root>
        );
      }}
    </StaticQuery>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerProps: PropTypes.oneOfType([PropTypes.object]),
  withHeader: PropTypes.bool,
  withFooter: PropTypes.bool,
};

Layout.defaultProps = {
  headerProps: undefined,
  withHeader: true,
  withFooter: true,
};

export default Layout;
