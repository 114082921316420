import React from 'react';
import PropTypes from 'prop-types';
import { rem, lighten } from 'polished';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { colors, breakpoint } from '../../theme';
import { getPathPrefix } from '../../utils/i18n';
import { MULTILINGUAL } from '../../constants';

// import Button from '../Button';
import DropdownMenu from '../DropdownMenu';
import LanguageSwitcher from '../LanguageSwitcher';
import Link from '../Link';

const TopBar = styled.header`
  position: relative;
  border-top: 5px solid ${(props) => props.theme.palette.colors.primary};
  background-color: ${(props) => props.theme.palette.colors.secondary};
`;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: ${(props) => props.theme.palette.background.body}; */
  padding: 0.4em 0;
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: 0 auto;

  ${breakpoint('large')} {
    padding: 1.3rem 0;
  }
`;

const LogoCol = styled.div`
  display: flex;
  margin: 0;
  padding: 0 ${(props) => props.theme.spacing.columnGutter};
  flex-shrink: 0;
  align-items: center;
`;

const NavigationColStart = styled.div`
  margin: 0;
  padding: 0 ${(props) => props.theme.spacing.columnGutter};
  flex-grow: 1;
  flex-shrink: 1;
  display: none;

  ${breakpoint('large')} {
    display: block;
  }
`;

const LanguageCol = styled.div`
  margin: 0;
  padding: 0 ${(props) => props.theme.spacing.columnGutter};
  flex-grow: 0;
  flex-shrink: 1;
  display: none;
  justify-content: flex-end;
  align-items: center;
  border-left: 1px solid ${(props) => props.theme.global.border.color};

  ${breakpoint('large')} {
    display: flex;
  }
`;

const ToggleCol = styled.div`
  margin: 0;
  padding-left: ${(props) => `${props.theme.spacing.columnGutter}`};
  padding-right: ${(props) => `${props.theme.spacing.columnGutter}`};
  flex-grow: 0;
`;

const MenuToggle = styled.button`
  appearance: none;
  background: none;
  border: 0;
  padding: 0.6rem 0.6rem 0.6rem 0;
  outline: 0;

  ${breakpoint('large')} {
    display: none;
  }
`;

const ToggleIcon = styled(FontAwesomeIcon)`
  font-size: ${rem(20)};
`;

const Logo = styled(Link)`
  display: block;
  color: white;
  text-decoration: none;
  width: ${rem(160)};
  flex-shrink: 0;

  ${breakpoint('large')} {
    width: ${rem(204)};
  }
`;

function Header({ logo, drawerToggle, navigationStart, backgroundColor }) {
  const { i18n } = useTranslation();

  return (
    <TopBar
      style={
        backgroundColor
          ? { backgroundColor, borderColor: lighten(0.1, backgroundColor) }
          : {}
      }
    >
      <Container>
        <LogoCol>
          <ToggleCol>
            <MenuToggle
              type="button"
              onClick={drawerToggle}
              color="default"
              aria-label="Mobile menu toggle"
            >
              <ToggleIcon icon={faBars} color={colors.white} />
            </MenuToggle>
          </ToggleCol>
          <Logo to={getPathPrefix(i18n.language)}>{logo}</Logo>
        </LogoCol>
        <NavigationColStart>
          <DropdownMenu items={navigationStart} color="negative" align="end" />
        </NavigationColStart>
        {MULTILINGUAL && (
          <LanguageCol>
            <LanguageSwitcher />
          </LanguageCol>
        )}
      </Container>
    </TopBar>
  );
}

Header.propTypes = {
  logo: PropTypes.node.isRequired,
  drawerToggle: PropTypes.func.isRequired,
  navigationStart: PropTypes.arrayOf(PropTypes.object),
  backgroundColor: PropTypes.string,
};

Header.defaultProps = {
  navigationStart: [],
  backgroundColor: undefined,
};

export default Header;
