import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { getContrast, lighten } from 'polished';

import Link from '../Link';

import { buttonVariants } from '../../theme';

// import LinkUtil from '../../utils/link';

const Root = styled(Link)`
  display: inline-block;
  cursor: pointer;
  border-radius: ${(props) => props.theme.components.button.radius};
  padding: ${(props) => props.theme.components.button.padding};
  appearance: none;
  user-select: none;
  text-align: center;
  font-size: ${(props) => props.theme.typography.button.buttonSizes.default};
  font-family: ${(props) => props.theme.typography.fonts.button};
  font-weight: ${(props) => props.theme.typography.weights.button.bold};
  text-transform: ${(props) => props.theme.typography.button.textTransform};
  letter-spacing: ${(props) => props.theme.typography.button.letterSpacing};
  transition: ${(props) =>
    `all ${props.theme.global.transition.duration} ${props.theme.global.transition.timing}`};
  box-shadow: 0 4px 8px 0 rgba(36, 36, 36, 0.44);

  &:hover {
    transform: scale(1.03);
  }

  ${({ variant }) => {
    switch (variant) {
      case 'hollow':
        return css`
          border: 2px solid ${(props) => props.theme.palette.colors.primary};
          color: ${(props) => {
            const contrastRatio = getContrast(
              props.theme.palette.background.content,
              '#fff',
            );
            return contrastRatio > 2.4 ? '#fff' : '#000';
          }};
          background-color: transparent;

          /* cubic-bezier(0.78, 0.14, 0.15, 0.86) */

          &:hover {
            background-color: ${(props) => props.theme.palette.colors.primary};
            color: ${(props) => {
              const contrastRatio = getContrast(
                props.theme.palette.colors.primary,
                '#fff',
              );
              return contrastRatio > 2.4 ? '#fff' : '#000';
            }};
          }

          ${buttonVariants('hollow')}

          &:focus {
            outline: none;
          }

          &:disabled {
            background: gray;
          }
        `;
      default:
        return css`
          border: none;
          color: ${(props) => {
            const contrastRatio = getContrast(
              props.theme.palette.colors.primary,
              '#fff',
            );
            return contrastRatio > 3 ? '#fff' : '#000';
          }};
          background-color: ${(props) => props.theme.palette.colors.primary};

          /* cubic-bezier(0.78, 0.14, 0.15, 0.86) */

          &:hover {
            background-color: ${(props) =>
              lighten(0.1, props.theme.palette.colors.primary)};
            color: ${(props) => {
              const contrastRatio = getContrast(
                props.theme.palette.colors.primary,
                '#fff',
              );
              return contrastRatio > 3 ? '#fff' : '#000';
            }};
          }

          ${buttonVariants('solid')}

          &:focus {
            outline: none;
          }

          &:disabled {
            background: gray;
          }
        `;
    }
  }}
`;

function Button({ children, link, variant, ...other }) {
  return (
    <Root
      variant={variant}
      to={link}
      as={typeof link === 'undefined' && 'div'}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {children}
    </Root>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  variant: PropTypes.oneOf(['fill', 'hollow']),
};

Button.defaultProps = {
  variant: 'fill',
  link: undefined,
};

export default Button;
