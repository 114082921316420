import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { rem, rgba } from 'polished';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

import { breakpoint } from '../../theme';

const Container = styled(BackgroundImage)`
  position: relative;
  display: inline-table;
  width: 100%;
  /* background-image: url(${(props) => props.fallbackBackground}); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: ${(props) => props.theme.palette.background.body};
  text-align: center;
  min-height: ${rem(350)};

  ${breakpoint('mediumlarge')} {
    min-height: ${rem(380)};
  }

  ${({ big }) =>
    big &&
    `height: ${rem(540)};

    ${breakpoint('mediumlarge')} {
      height: ${rem(600)};
    }
  
    ${breakpoint('large')} {
      height: ${rem(700)};
    }`}
`;

const Tint = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${(props) =>
    rgba(props.theme.palette.colors.greyDark, 0.85)};
`;

const Inner = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const Content = styled.div`
  position: relative;
  max-width: 58rem;
  margin: 0 auto;
  color: ${(props) => props.theme.palette.colors.white};
  /* padding: ${rem(74 + 40)} 1.5rem ${rem(40)}; */
  padding: ${rem(40)} 1.5rem ${rem(40)};
  font-size: ${rem(17)};
  font-weight: ${(props) => props.theme.typography.weights.button.normal};

  ${breakpoint('mediumlarge')} {
    font-size: ${rem(18)};
  }

  ${breakpoint('large')} {
    /* font-size: ${rem(19)}; */
    /* padding-top: ${rem(91 + 70)}; */
    padding-top: ${rem(70)};
    padding-bottom: ${rem(70)};
  }

  p {
    margin: 0.5rem 0 0 0;
  }
`;

const Children = styled.div`
  position: relative;
  margin-top: ${rem(20)};

  ${breakpoint('mediumlarge')} {
    padding: 0 2rem;
  }
`;

const Actions = styled.div`
  width: 100%;
  margin: 1.5rem auto 0;
`;

const HeroHeading = styled.h2`
  margin: 0;
  font-size: ${rem(36)};
  font-weight: ${(props) => props.theme.palette.colors.white};
  color: ${(props) => props.theme.palette.colors.white};

  ${breakpoint('medium')} {
    font-size: ${rem(38)};
  }

  ${breakpoint('mediumlarge')} {
    font-size: ${rem(42)};
  }

  ${breakpoint('large')} {
    font-size: ${rem(46)};
  }

  strong,
  b {
    color: ${(props) => props.theme.palette.colors.primary};
  }
`;

const PreHeading = styled.h3`
  margin: 0 0 ${rem(16)} 0;
  font-size: ${rem(14)};
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.colors.white};
  opacity: 0.75;
  letter-spacing: 0.07em;
  font-family: ${(props) => props.theme.typography.fonts.button};
  font-weight: ${(props) => props.theme.typography.weights.button.normal};

  ${breakpoint('mediumlarge')} {
    font-size: ${rem(16)};
  }

  ${breakpoint('large')} {
    font-size: ${rem(18)};
  }
`;

const query = graphql`
  query {
    fileName: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

function Hero({
  heading,
  preHeading,
  children,
  actions,
  big,
  tint,
  image,
  pageTitle,
}) {
  return (
    <StaticQuery
      query={`${query}`} // String hack needed to fix https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    >
      {({ fileName: fallbackBackground }) => {
        return (
          <Container
            Tag="div"
            big={big}
            fluid={
              image?.childImageSharp?.fluid ||
              fallbackBackground?.childImageSharp?.fluid
            }
          >
            {tint && <Tint />}
            <Inner>
              <Content>
                {preHeading && <PreHeading>{preHeading}</PreHeading>}
                <HeroHeading as={pageTitle && 'h1'}>{heading}</HeroHeading>
                {children && <Children>{children}</Children>}
                {actions && <Actions>{actions}</Actions>}
              </Content>
            </Inner>
          </Container>
        );
      }}
    </StaticQuery>
  );
}

Hero.propTypes = {
  heading: PropTypes.node,
  preHeading: PropTypes.string,
  children: PropTypes.node,
  actions: PropTypes.node,
  big: PropTypes.bool,
  tint: PropTypes.bool,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pageTitle: PropTypes.bool,
};

Hero.defaultProps = {
  heading: '',
  children: '',
  actions: '',
  preHeading: '',
  big: false,
  tint: false,
  image: null,
  pageTitle: false,
};

export default Hero;
