import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AccordionItem from './AccordionItem';

const Root = styled.nav`
  /* display: flex;
  align-items: center;
  flex-flow: wrap; */
`;

function AccordionMenu({ items, color }) {
  return (
    <Root>
      {items.map((item) => {
        return <AccordionItem key={item.id} item={item} color={color} />;
      })}
    </Root>
  );
}

AccordionMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  color: PropTypes.oneOf(['default', 'negative']),
};

AccordionMenu.defaultProps = {
  color: 'default',
};

export default AccordionMenu;
