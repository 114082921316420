import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'gatsby';

function Link({ to, children, activeClassName, partiallyActive, ...other }) {
  return typeof to === 'string' && /^https?:\/\/|mailto:/.test(to) ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <a href={to} target="_blank" rel="noopener noreferrer" {...other}>
      {children}
    </a>
  ) : (
    <RouterLink
      to={to}
      activeClassName={activeClassName}
      partiallyActive={partiallyActive}
      {...other} // eslint-disable-line react/jsx-props-no-spreading
    >
      {children}
    </RouterLink>
  );
}

Link.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func])
    .isRequired,
  children: PropTypes.node,
  activeClassName: PropTypes.string,
  partiallyActive: PropTypes.bool,
};

Link.defaultProps = {
  children: '',
  activeClassName: '',
  partiallyActive: false,
};

export default Link;
