import React from 'react';

import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import logo from '../../images/logo/logo.svg';

// const LogoWrap = styled.div`
//   display: block;
//   width: ${(props) => props.theme.grid.maxWidth};
//   padding: 0 1rem 1.5rem;
// `;

const Logo = styled.img`
  display: block;
  width: 100%;
`;

const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

function SiteLogo({ ...other }) {
  return (
    <StaticQuery
      query={`${query}`} // String hack needed to fix https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    >
      {({ site }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Logo src={logo} alt={site.siteMetadata.title} {...other} />
      )}
    </StaticQuery>
  );
}

export default SiteLogo;
