import React, { useState } from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import {
  // Trans,
  useTranslation,
} from 'react-i18next';
import { rgba } from 'polished';

// import Link from '../Link';
import Button from '../Button';

import { breakpoint } from '../../theme';

const Root = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100;
  max-width: 100%;
  width: 37rem;
  padding: ${(props) => props.theme.spacing.gutter} 0.5rem;
`;

const Card = styled.div`
  padding: ${(props) => props.theme.spacing.gutter};
  background-color: ${(props) => rgba(props.theme.palette.colors.grey, 0.92)};
  width: 100%;
  overflow: hidden;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 0.95rem;
`;

const Text = styled.div`
  a {
    color: ${(props) => props.theme.palette.colors.cta};
    font-weight: ${(props) => props.theme.typography.weights.primary.bold};
  }
`;

const ButtonWrap = styled.div`
  margin: 1rem -0.5rem 0;

  display: flex;
  align-items: flex-start;

  > * {
    font-size: 0.75rem;

    ${breakpoint('large')} {
      font-size: 0.85rem;
    }
  }

  a {
    color: ${(props) => props.theme.palette.colors.black} !important;
    background-color: ${(props) => props.theme.palette.colors.white} !important;
    padding: 0.5rem 0.75rem !important;
  }
`;

function CookieConsent() {
  const defaultVisibility =
    typeof window !== 'undefined' &&
    window.localStorage.getItem('dnt') === null;

  const [isVisible, setVisible] = useState(defaultVisibility);
  const {
    t,
    // i18n
  } = useTranslation();

  if (!isVisible) {
    return null;
  }

  // const settings = {
  //   privacyPolicy: { path: '/integritetspolicy' },
  // };

  function handleConsent() {
    window.localStorage.setItem('dnt', 0);
    setVisible(false);
    navigate(0);
  }

  function handleReject() {
    window.localStorage.setItem('dnt', 1);
    setVisible(false);
  }

  const buttonStyle = {
    // fontSize: '0.75rem',
    padding: '0.55em 1.2em',
    margin: '0 0.5rem',
  };

  return (
    <Root>
      <Card>
        <Text>
          {t('consentMessage')}
          {/* <br />
          <Trans i18nKey="consentPrivacy">
            Read about how we use your data in our{' '}
            <Link to={settings.privacyPolicy.path}>privacy policy</Link>.
          </Trans> */}
        </Text>
        <ButtonWrap>
          <Button type="button" onClick={handleReject} style={buttonStyle}>
            {t('consentReject')}
          </Button>
          <Button
            type="button"
            className="cta"
            onClick={handleConsent}
            style={buttonStyle}
          >
            {t('consentAccept')}
          </Button>
        </ButtonWrap>
      </Card>
    </Root>
  );
}

export default CookieConsent;
