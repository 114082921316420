/* eslint-disable import/prefer-default-export */
import config from '../i18n-config';

export function getPathPrefix(lang) {
  if (lang === config.defaultLang && !config.useDefaultLangInUrl) {
    return '/';
  }

  const urlLang = config.shortenUrlLangs ? lang.slice(0, 2) : lang;

  return `/${urlLang}/`;
}
